<template>
  <div class="home">
    <el-container class="root-container">
      <el-header class="header">
        <my-header />
      </el-header>
      <el-container>
        <el-aside 
          class="aside" 
          width="200px" 
          element-loading-text="菜单加载中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.4)">
          <left-menu @loaded="menuLoaded" />
        </el-aside>
        <el-container>
          <el-main class="main">
            <router-view />
          </el-main>
          <el-footer class="footer">
              <my-footer />
          </el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      menuLoading: true
    }
  },
  methods: {
    menuLoaded() {
      this.menuLoading = false;
    }
  },
  components: {
    'left-menu': () => import('./LeftMenu'),
    'my-header': () => import('./MyHeader'),
    'my-footer': () => import('./MyFooter')
  }
};
</script>
<style lang="less">
.home {
  height: 100%;
  .root-container {
    height: 100%;

    .header {
      background-color: rgb(84, 92, 100);
      height: 60px;
    }

    .aside {
      border-top: 1px solid rgb(99, 98, 98);
      background-color: rgb(84, 92, 100);
      overflow: visible;
    }

    .main {
      padding: 0px;
      height: calc(100% - 120)px;
    }

    .footer {
      border-top: @hair-border-ccc;
      height: 60px;
    }
  }
}
</style>